import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import ListItemStyled from './ListItemStyled';
import { isFunction, isHavingValue, isNullOrUndefined, orElse } from 'utils/objectUtils';
import { Box, css, Typography } from '@mui/material';
import { isString } from 'utils/stringUtils';
import { ReactNode } from 'react';
import { ListItemVariant } from './ListItemStyledTypes';

export interface ListItem extends Object {
  key?: string
  deleted?: boolean
}

export interface SimpleListProps<T> {
  items?:T[], 
  renderItem?: (item:T, index: number)=>ReactNode
  onItemClick?: (arg:{event:any, data: T}) => void
  selectable?: boolean
  allCheckedDefault?: boolean
  getRowKey?: string | ((item:T)=>string)
  useIndexAsRowKey?: boolean
  maxItems?: number
  maxItemsShowMoreEnabled?: boolean
  itemVariant?: ListItemVariant
  emptyState?: ReactNode
  listItemProps?: any|((item:T)=>any)
  defaultEmptyState?: boolean
  maxHeight?: string | number
  hideTopBottomDividers?: boolean
  strikeDeleted?: boolean
}

const rootStyle = css({
  width: '100%'
})

/**
 * 
 * items must have key and title
 * 
 * @returns 
 */
export default function SimpleList<T extends ListItem>({items, selectable, onItemClick, allCheckedDefault, renderItem, getRowKey, useIndexAsRowKey, maxItems, maxItemsShowMoreEnabled, itemVariant, emptyState, listItemProps, defaultEmptyState, maxHeight, hideTopBottomDividers, strikeDeleted}:SimpleListProps<T>) {
  
  if (allCheckedDefault) {
    if (items) {
      const allHaveKeys = items.every(i=>i.key!==null&&i.key!==undefined)
      if (!allHaveKeys) {
        throw new Error('list used as choice element. all items must have keys.')
      }
    }
  }

  const [checked, setChecked] = useState(allCheckedDefault ? items.map(({key})=>key) : [])

  const [maxItemsEffective, setMaxItemsEffective] = useState(maxItems)

  const itemsWindow = isHavingValue(maxItemsEffective) ? (items.length <= maxItemsEffective ? items : items.slice(0, maxItemsEffective)) : items
  const more = isHavingValue(maxItemsEffective) ? (items.length - itemsWindow.length) : null

  itemVariant = orElse(itemVariant, 'withDividers')

  if (isNullOrUndefined(getRowKey) && useIndexAsRowKey !== true) {
    console.error('getRowKey should be set')
  }

  const handleToggle = (key) => () => {
    const currentIndex = checked.indexOf(key);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(key);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  }

  const handleShowMoreClick = () => {
    setMaxItemsEffective(c=>c+10)
  }

  const getRowKeyInternal = (item, index) => {

    const getRowKeyIsFunction = isHavingValue(getRowKey) && isFunction(getRowKey)

    if (getRowKeyIsFunction) {
      //@ts-ignore is usabled as function is checked
      return getRowKey(item)
    } else if (getRowKey) {
      //@ts-ignore is usabled as string is checked
      const key = item[getRowKey]
      if (!isHavingValue(key)) {
        console.error('key from getRowKey property is ' + key)
      }
      return key
    } else {
      return index
    }
  }

  const renderItemInner = (item:T, index) => {

    const key = getRowKeyInternal(item, index)

    const labelId = `checkbox-list-label-${key}`;

    const handleItemClick = (event) => {
      onItemClick({
        ...event,
        data: item, 
      })
    }

    let listItemPropsEffective = listItemProps;
    if (listItemProps && isFunction(listItemPropsEffective)) {
      listItemPropsEffective = listItemProps(item)
    }

    return (
      <ListItemStyled 
        idx={index} 
        key={key} 
        role={undefined} 
        dense 
        button={selectable === true || onItemClick? true : null} 
        onClick={onItemClick ? handleItemClick : (selectable === true ? handleToggle(key) : null)} 
        strike={(strikeDeleted && item.deleted)||null}
        variant={itemVariant}
        {...listItemPropsEffective}
        >
        {renderItem ?
          renderItem(item, index)
          :
          <ListItemText id={labelId} primary={isString(item) ? <>{item}</> : <>{JSON.stringify(item)}</>} />
        }
      </ListItemStyled>
    )
  }

  const renderEmptyState = () => {
    if (emptyState) {
      return emptyState;
    }
    else if (defaultEmptyState === true) {
      return <Box color="text.secondary" py={2} ><Typography fontSize="0.9em">No entries</Typography></Box>
    }
    return null
  }

  if (!isHavingValue(itemsWindow)) {
    return null;
  }

  let wrapperStyle = [{width: '100%', display: 'flex'}] as any[]
  if (maxHeight) {
    wrapperStyle[0].maxHeight = maxHeight
    wrapperStyle[0].overflowY = 'auto'
  }

/*   if (listItemProps?.hoverHighlightOverflow) {
    //adds pseudo space left and right so that the hoverHighlightOverflow is not cut due to the overflow scroll
    wrapperStyle.push({
      overflowY: 'scroll',
      margin: '0px',
      width: 'calc(100% + 20px)',
      paddingLeft: '10px',
      paddingRight: '10px',
      marginLeft: '-10px'
    })
  } */

  const listSx = [{ padding: 0, margin: 0, flexGrow: 1, width: '100%' }] as any[]

  if ((itemVariant === 'withDividers' || listItemProps?.showDividers) && hideTopBottomDividers !== true) {
    listSx.push((theme)=>({
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
    }))
  }

  return (
    <>
      {
        itemsWindow.length > 0 ?
        <div css={rootStyle}>
          <div css={css(wrapperStyle)}>
            <List sx={listSx}>
              {itemsWindow.map(renderItemInner)}
            </List>
          </div>
          {more > 0 && <Typography>{`+ ${more} more`} {maxItemsShowMoreEnabled&&<Typography color="text.secondary" component="span" onClick={handleShowMoreClick} style={{cursor: 'pointer'}}>Show</Typography>}</Typography>}
        </div>
        :
        renderEmptyState()
      }

    </>
  )
}
